// ** Next Imports
import Image from "next/image";

// ** React imports
import { Row, Col } from "react-bootstrap";

// ** FA Imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPhoneAlt,
  faComments,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

// ** Style imports
import styles from "./styles/topbar.module.scss";

// ** Data Imports
import { CONTACT_INFO } from "../../helpers/DATA";

// ** Image Imports
import usFlagImage from "../../../public/images/usflag.png";

const TopBar = () => (
  <section className={styles.topBar}>
    <div className="container">
      <Row>
        <Col sm={4} xs={12}>
          <div className={styles.topbarLeft}>
            <div
              onClick={() =>
                alert(
                  "EIN has 100% US based customer support, and 100% US Ownership "
                )
              }
              style={{
                display: "inline-block",
                maxWidth: "60px",
                cursor: "pointer",
              }}
            >
              <figure
                style={{
                  marginBottom: "0 !important",
                }}
              >
                <Image
                  src={usFlagImage}
                  className="img-fluid"
                  style={{
                    maxHeight: "100px",
                    maxWidth: "52px",
                  }}
                  placeholder="blur"
                  alt="100% American"
                />
                <figcaption>100%</figcaption>
              </figure>
            </div>

            <a
              href="https://my.easyinternetnow.com/portal/easyinternetnow/login"
              target="_blank"
              rel="noopener noreferrer"
              title="Customer Portal Login to manage account"
              style={{
                float: "right",
              }}
            >
              Customer Portal
            </a>
          </div>
        </Col>
        <Col sm={8} xs={12}>
          <div className={styles.topbarRight}>
            <a href={`tel:${CONTACT_INFO.call.number}`}>
              <FontAwesomeIcon icon={faPhoneAlt} /> {CONTACT_INFO.call.text}
            </a>
            <a href={`sms:${CONTACT_INFO.sms.number}`}>
              <FontAwesomeIcon icon={faComments} />
              {` Text: ${CONTACT_INFO.sms.text}`}
            </a>
            <a href={`mailto:${CONTACT_INFO.email}`}>
              <FontAwesomeIcon icon={faEnvelope} /> {CONTACT_INFO.email}
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/easyinternetnow/"
              rel="noopener noreferrer"
              aria-label="Vist out Facebook page"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              target="_blank"
              href="https://twitter.com/EasyInternetNow"
              rel="noopener noreferrer"
              aria-label="Vist out Twitter page"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/easyinternetnow/"
              rel="noopener noreferrer"
              aria-label="Vist out Instagram page"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
        </Col>
      </Row>
    </div>
  </section>
);

export default TopBar;
