import Head from "next/head";
import { globalMetaData } from "../../helpers/DATA";

import TopBar from "./TopBar";
import Header from "./Header";
import Footer from "./Footer";
import { Fragment } from "react";

// ** Types
interface LayoutProps {
  children: React.ReactNode;
}

interface SEOProps {
  title: string;
  description?: string;
  canonicalUrl?: string;
  ogType?: string;
  ogImgUrl?: string;
  structuredData?: string;
  children?: React.ReactNode;
}

//Layout container
const Layout = (props: LayoutProps) => (
  <Fragment>
    <TopBar />
    <Header />
    {props.children}
    <Footer />
  </Fragment>
);
export default Layout;

export const SEOHead = ({
  title = globalMetaData.siteName,
  description = globalMetaData.description,
  canonicalUrl = globalMetaData.siteUrl,
  ogType = globalMetaData.type,
  ogImgUrl = globalMetaData.siteLogo,
  structuredData = globalMetaData.structuredData,
  children,
}: SEOProps) => {
  return (
    <Head>
      {/*
            Fundamental head elements important for SEO.
      */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#5bbad5" />
      <meta name="robots" content="index, follow" />
      <meta
        name="googlebot"
        content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      <meta
        name="bingbot"
        content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />

      {/*
            Open graph meta tags.
      */}
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content={globalMetaData.siteName} />
      <meta property="og:type" content={ogType} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImgUrl} />
      <meta property="og:url" content={canonicalUrl} />

      <meta property="article:publisher" content={globalMetaData.fbPublisher} />

      <meta
        property="article:modified_time"
        content="2022-12-15T15:15:00+00:00"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={globalMetaData.twitterCreator} />
      <meta name="twitter:site" content={globalMetaData.twitterCreator} />

      {/*
            Structured data.
      */}
      {structuredData.length > 0 && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: structuredData }}
          key="item-jsonld"
        />
      )}

      {/*
        ICONS
      */}

      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="/images/icons/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/images/icons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/images/icons/favicon-16x16.png"
      />
      <link rel="manifest" href="/images/icons/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/images/icons/safari-pinned-tab.svg"
        color="#5bbad5"
      />
      <link rel="shortcut icon" href="/images/icons/favicon.ico" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta
        name="msapplication-config"
        content="/images/icons/browserconfig.xml"
      />

      {/*
        Add SEO chilren
      */}
      {children}
    </Head>
  );
};
